import React from "react";
import TextField from "@material-ui/core/TextField";

/**
 * Email input component
 *
 * @todo There is a PR on Formik / Yup to fix transforms https://github.com/jaredpalmer/formik/pull/728
 * @todo Currently this is a bad implmentation and should be done on the schema level once this PR is merged.
 * @author Andrew McLagan <andrew@ethicaljobs.com.au>
 *
 * @param {object} props
 */
export default props => (
  <TextField
    {...props}
    fullWidth={true}
    onChange={event => {
      event.target.value = event.target.value.trim();
      return props.onChange(event);
    }}
  />
);
