import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import EthicalJobsAPI from '@ethical-jobs/sdk-js';
import Amplitude from 'amplitude-js';

import { APIContext, AmplitudeContext } from 'contexts';
import { getAmplitudeInstance } from 'lib/amplitude';

import theme from 'theme';
import CreateAlert from 'containers/CreateAlert';
import Confirm from 'containers/Confirm';
import Unsubscribe from 'containers/Unsubscribe';
import Manage from 'containers/Manage';
import Logo from 'components/Logo';
import Footer from 'components/Footer';

const amplitude = Amplitude.getInstance();
amplitude.init('8b25618fe58a321055ebf44b4395625d');

export default class App extends React.Component {
  componentDidCatch(error, errorInfo) {
    amplitude.logEvent('Error', { site: 'Alerts', environment: process.env.NODE_ENV, location: 'containers/App/index', error: JSON.stringify(error), info:  JSON.stringify(errorInfo) });
  }

  render () {
    return (
      <AmplitudeContext.Provider value={getAmplitudeInstance(amplitude)}>
        <APIContext.Provider value={new EthicalJobsAPI()}>
          <Router>
            <MuiThemeProvider theme={theme}>
              <CssBaseline>
                <div className="container">
                  <div className="content">
                    <Logo />
                    <div style={{ width: '100%' }}>
                      <Switch>
                        <Route
                          path="/confirm/:subscriptionUuid"
                          component={Confirm}
                        />
                        <Route
                          path="/unsubscribe/:subscriptionUuid"
                          component={Unsubscribe}
                        />
                        <Route
                          path="/create/:email?/:firstName?"
                          component={CreateAlert}
                        />
                        <Route
                          path="/manage"
                          component={Manage}
                        />
                        <Redirect to='/create' />
                      </Switch>
                    </div>
                  </div>
                  <Footer />
                </div>
              </CssBaseline>
            </MuiThemeProvider>
          </Router>
        </APIContext.Provider>
      </AmplitudeContext.Provider>
    );
  }
}
