export const getAmplitudeInstance = (amplitudeInstance) => ({
  logEvent: (type, properties) => {
    if (type === 'Error' && properties.message === 'Network Error') return;
    if (type === 'Error' && properties.message === 'Request aborted') return;

    amplitudeInstance.logEvent(type, {
        site: 'Alerts',
        environment: process.env.NODE_ENV,
        ...properties,
    });
  },
  setUserProperties: (properties) => amplitudeInstance.setUserProperties(properties)
});