import React from "react";
import { stringify } from 'query-string';
import { NavLink } from "react-router-dom";
import Button from 'components/Button';

export const Manage = ({ label  = '', email = '', secret = '' }) => (
  <NavLink
    className="btn btn-primary btn-medium btn-outline"
    isActive={(match, location) => location.pathname.startsWith('/manage')}
    to={{
      pathname: `/manage`,
      search: stringify({ email, secret }),
    }}
  >{label}</NavLink>
);

export const Create = ({ label  = '', email = '', secret = '' }) => (
  <NavLink
    className="btn btn-primary btn-medium btn-outline"
    isActive={(match, location) => location.pathname.startsWith('/create')}
    to={{
      pathname: `/create/${email}`,
      search: stringify({ email, secret }),
    }}
  >{label}</NavLink>
);

export const Search = () => (
  <a
    href="https://www.ethicaljobs.com.au"
    className="btn btn-primary btn-medium btn-outline"
  >Back to job search</a>
);

export const UnsubAll = ({ unsubAll }) => (
  <Button
    className="btn btn-primary btn-medium btn-outline"
    onClick={unsubAll}
  >Unsubscribe All</Button>
);
