import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

export default ({ value = 'DAILY', onChange }) => (
  <FormControl>
    <NativeSelect
      value={value}
      onChange={event => onChange(event)}
      name="frequency"
    >
      <option value="DAILY">Daily emails</option>
      <option value="WEEKLY">Weekly emails</option>
    </NativeSelect>
  </FormControl>
);
