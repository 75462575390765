import React, { useState, useCallback, useEffect, useContext } from 'react';

import { Modal } from '@material-ui/core';
import ReactGA from 'react-ga';

import { APIContext, AmplitudeContext } from 'contexts';
import Button from 'components/Button';
import SubscriptionsList from 'components/SubscriptionsList';
import Loading from 'components/Loading';
import Navigation from 'components/Navigation';

const Manage = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [showConfirmUnsubscribeModal, setShowUnsubscribeModal] = useState(false);

  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  useEffect(() => { amplitude.logEvent('Route', { site: 'Alerts', type: 'Open Page', page: 'Manage' }); }, [amplitude]);

  useEffect(() => {
    const fn = async () => {
      setFetching(true);

      try {
        let { alerts } = await api.getAlertsForJobSeeker();
        setSubscriptions(alerts);
      } catch (error) {
        amplitude.logEvent('Error', { site: 'Alerts', location: 'containers/Manage:getAlertsForJobSeeker', error: error.message });
      }

      setFetching(false);
    };

    fn();
  }, [api, amplitude]);

  const unsubscribe = useCallback(async (subscriptionUuid) => {  
    try {  
      ReactGA.event({
        category: 'alerts',
        action: 'unsubscribe'
      });
      await api.unsubscribeFromAlert(subscriptionUuid);
      amplitude.logEvent('API', { site: 'Alerts', function: 'unsubscribeFromAlert', arguments: { subscriptionUuid } });
      setSubscriptions(prevSubscriptions => prevSubscriptions.filter(prevSub => prevSub.uuid !== subscriptionUuid));
    } catch (error) {
      amplitude.logEvent('Error', { site: 'Alerts', location: 'containers/Manage/unsubscribe', error: error.message });
    }
  }, [api, amplitude]);

  const unsubscribeAll = useCallback(async () => {
    ReactGA.event({
      category: 'alerts',
      action: 'unsubscribe'
    });
    
    try {
      await Promise.all(subscriptions.map(subscription => api.unsubscribeFromAlert(subscription.uuid)));
      amplitude.logEvent('API', { site: 'Alerts', function: 'unsubscribeAll', arguments: { subscriptionIds: subscriptions.map(subscription => subscription.uuid) } });
    } catch (error) {
      amplitude.logEvent('Error', { site: 'Alerts', location: 'containers/Manage/unsubscribeAll', error: error.message });
    }
    setSubscriptions([]);
  }, [subscriptions, api, amplitude]);

  const update = useCallback(async (subscriptionUuid, attributes) => {
    ReactGA.event({
      category: 'alerts',
      action: 'update'
    });
    try {
      let response = await api.updateAlert(subscriptionUuid, attributes);
      amplitude.logEvent('API', { site: 'Alerts', function: 'updateAlert', arguments: { subscriptionUuid, attributes } });
      setSubscriptions(response);
    } catch (error) {
      amplitude.logEvent('Error', { site: 'Alerts', location: 'containers/Manage/update', error: error.message });
    }
    
  }, [api, amplitude]);

  return (
    <div className="manage-container">
      <div className="card transparent animated fadeIn">
        <h3>Manage your EthicalJobs.com.au job alerts</h3>
        <p>Update your job alerts below. We’ll send you a daily or weekly email if there are new jobs matching your search criteria.</p>
      </div>
      <Loading isLoading={fetching} list={true}>
        <SubscriptionsList
          subscriptions={subscriptions}
          unsubscribe={unsubscribe}
          update={update}
        />
        <Navigation
          showUnsubscribeLink={subscriptions.length > 1}
          unsubscribeAll={() => setShowUnsubscribeModal(true)}
        />
      </Loading>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showConfirmUnsubscribeModal}
        onClose={() => setShowUnsubscribeModal(false)}
      >
        <div style={{ position: 'absolute', top: `50%`, left: `50%`, transform: `translate(-50%, -50%)`, width: 300, backgroundColor: 'white', borderRadius: 5, padding: 15 }}>
          <h2 style={{ marginBottom: 5 }}>Are you sure?</h2>
          <p>This will unsubscribe you from all of your EthicalJobs alerts?</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setShowUnsubscribeModal(false)}>Cancel</Button>
            <Button onClick={() => {
              unsubscribeAll();
              setShowUnsubscribeModal(false);
            }}>OK</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(Manage);
