import React from 'react';
import classNames from 'classnames';
import SubscribeImage from 'images/Subscribe';
import UnsubscribeImage from 'images/Unsubscribe';

const Error = ({ error }) => (
  <div>
    <UnsubscribeImage />
    {error.statusCode === 409 && error.message === 'unconfirmed-subscription-exists' &&
      <div>
        <h3>{"You're already subscribed to this alert but have not yet confirmed it."}</h3>
        <p>{"Please check your email to activate your alert."}</p>
      </div>}
    {error.statusCode === 409 && error.message === 'subscription-exists' &&
      <div>
        <h3>{"You're already subscribed to this alert"}</h3>
        <p>{"You will receive an email when there are new jobs matching your search."}</p>
      </div>}
    {error.statusCode !== 409 &&
      <div>
        <h3>{"Oops! Something went wrong"}</h3>
        <p>{"Our engineers have been informed and we're working on a solution."}</p>
      </div>}
  </div>
);

const Success = () => (
  <div>
    <h1>{"Almost finished…"}</h1>
    <SubscribeImage />
    <p>{"Please check your email to confirm your job alert."}</p>
  </div>
);

export default ({ visible, error }) => {
  if (visible === false) {
    return null;
  }
  return (
    <div className={classNames('submitted card animated fadeIn', { error })}>
      {error && <Error error={error} />}
      {!error && <Success />}
    </div>
  );
}
