import React, { useState, useEffect, useContext } from 'react';

import { useParams } from 'react-router';
import ReactGA from 'react-ga';

import { APIContext, AmplitudeContext } from 'contexts';
import Confirmed from 'components/Confirmed';
import Loading from 'components/Loading';
import Navigation from 'components/Navigation';

const Confirm = () => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const { subscriptionUuid } = useParams();

  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  useEffect(() => {
    amplitude.logEvent('Route', { site: 'Alerts', type: 'Open Page', page: 'Confirm' });
  }, [amplitude]);

  useEffect(() => {
    const fn = async () => {
      setError(false);
      setFetching(true);
      
      try {
        ReactGA.event({
          category: 'alerts',
          action: 'confirm',
        });

        await api.confirmAlertSubscription(subscriptionUuid);
        amplitude.logEvent('API', { site: 'Alerts', function: 'confirmAlertSubscription', arguments: { subscriptionUuid } });
      } catch (error) {
        amplitude.logEvent('Error', { site: 'Alerts', location: 'containers/Confirm/index', error: error.message });
        setError(error.message);
      }

      setFetching(false);
    };

    if (subscriptionUuid) {
      fn();
    }
  }, [subscriptionUuid, api, amplitude]);
  
  
  return (
    <Loading isLoading={fetching}>
      <Confirmed error={error} visible />
      <Navigation />
    </Loading>
  );
};

export default Confirm;