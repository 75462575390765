import React from 'react';
import ContentLoader from 'react-content-loader';

const List = () => [
  <rect key="1" x="0" y="0" rx="3" ry="3" width="100%" height="80" />,
  <rect key="2" x="0" y="110" rx="3" ry="3" width="100%" height="80" />,
  <rect key="3" x="0" y="220" rx="3" ry="3" width="100%" height="80" />,
];

const Boxed = () => [
  <rect key="1" x="0" y="0" rx="3" ry="3" width="100%" height="250" />,
  <rect key="2" x="0" y="270" rx="3" ry="3" width="100%" height="45" />,
];

export default ({ isLoading = true, list = false, children }) => {
  if (isLoading === false) {
    return children;
  }
	return (
    <div style={{ margin: "0 auto 30px auto", width: '600px' }}>
      <ContentLoader
	    	height={300}
	    	width={500}
	    	speed={1}
	    	primaryColor="#e4e5e8"
	    	secondaryColor="#ecedf2"
	    >
        {list === true && <List />}
        {list === false && <Boxed />}
	    </ContentLoader>
    </div>
  );
};
