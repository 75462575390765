import React from "react";
import EmptyImage from 'images/Empty';

export default () => (
  <div className="empty center card animated fadeIn">
    <EmptyImage />
    <h3>{"You have no job alerts."}</h3>
    <p>{"Create a new alert to receive jobs matching your search criteria."}</p>
  </div>
);
