import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

export default ({ value, onChange }) => (
  <FormControl component="fieldset" fullWidth={true} required>
    <RadioGroup
      aria-label="frequency"
      name="frequency"
      value={value}
      onChange={onChange}
      style={{ flexDirection: "row" }}
    >
      <FormControlLabel value="DAILY" label="Daily" control={<Radio />} />
      <FormControlLabel value="WEEKLY" label="Weekly" control={<Radio />} />
    </RadioGroup>
  </FormControl>
);
