import React from 'react';
import classNames from 'classnames';
import SubscribeImage from 'images/Subscribe';
import UnsubscribeImage from 'images/Unsubscribe';

const Error = ({ code }) => (
  <div>
    <UnsubscribeImage />
    {code === 401 && (
      <div>
        <h3>{"Something went wrong..."}</h3>
        <p>{"The link may have expired or be invalidated to protect your privacy."}</p>
      </div>
    )}
    {code !== 401 && (
      <div>
        <h3>{"Something went wrong..."}</h3>
        <p>{"Our engineers have been informed and we are working on a solution. Please come back and try again later."}</p>
      </div>
    )}
  </div>
);

const Success = ({ jobSeekerUuid }) => (
  <div>
    <SubscribeImage />
    <h3>{"Your job alert has been confirmed!"}</h3>
    <p>{"You will now receive the latest ethical jobs matching your search to your inbox."}</p>
  </div>
);

export default ({ visible, error, jobSeekerUuid }) => {
  if (visible === false) {
    return null;
  }
  return (
    <div className={classNames('submitted card animated fadeIn', { error })}>
      {error && <Error code={error} />}
      {!error && <Success jobSeekerUuid={jobSeekerUuid} />}
    </div>
  );
}
