import { withFormik } from "formik";
import get from 'lodash/get';
import Validators from "lib/validators";
import CreateAlertForm from "./Form";

/**
 * Map properties to form values
 * @param {object} props
 * @return {object}
 */
function mapPropsToValues(props) {
  return {
    firstName: get(props, 'firstName', ''),
    email: get(props, 'email', ''),
    frequency: "DAILY",
    params: props.params,
  };
}

/**
 * Submit form
 * @param {object} values
 * @param {object} bag
 * @return {Promise}
 */
function submit(values, { props }) {
  return props.subscribe(values);
}

export default withFormik({
  displayName: "CreateAlertForm",
  validationSchema: Validators.SubscribeSchema,
  handleSubmit: submit,
  enableReinitialize: true,
  mapPropsToValues
})(CreateAlertForm);
