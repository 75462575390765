import React, { useState, useCallback, useEffect, useContext } from 'react';

import { useParams } from 'react-router';
import ReactGA from 'react-ga';

import { APIContext, AmplitudeContext } from 'contexts';
import Helpers from 'lib/helpers';

import CreateAlertForm from 'forms/CreateAlertForm';
import Subscribed from 'components/Subscribed';
import Loading from 'components/Loading'; 
import Navigation from 'components/Navigation';

/**
 * Sometimes marketing link to web-alerts with the email address and/or first name
 * in the url, e.g. /create/howdy@ethicaljobs.com.au/john
 *
 * To make this cleaner for the app we request that they use an encoded email address and name,
 * and we decode here. Ideally this decoding should be done further up the chain, but this
 * works for now.
 */
export const CreateAlert = () => {
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [taxonomies, setTaxonomies] = useState({});
  const [jobSeeker, setJobSeeker] = useState({ firstName: '' });

  const { email, firstName } = useParams();

  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  useEffect(() => { amplitude.logEvent('Route', { site: 'Alerts', type: 'Open Page', page: 'Create Alert' }); }, [amplitude]);

  useEffect(() => {
    const fn = async () => {
      setFetching(true);
      const taxonomiesResponse = await api.getTaxonomies();
      setTaxonomies(taxonomiesResponse);

      if (Helpers.hasCredentials()) {
        try {
          let jobSeekerResponse = await api.getAlertsForJobSeeker();
          setJobSeeker(jobSeekerResponse);
        } catch (error) {
          amplitude.logEvent('Error', { site: 'Alerts', location: 'containers/CreateAlert/index:getAlertsForJobSeeker', error: error.message });
        }
      }

      setFetching(false);
    }

    fn();
  }, [api, amplitude]);

  const subscribe = useCallback(async ({ email, firstName, frequency, params }) => {
    setError(false);

    try {
      ReactGA.event({
        category: 'alerts',
        action: 'subscribe',
        dimension2: params.keywords,
        dimension3: params.categories,
        dimension4: params.locations,
        dimension5: params.workTypes,
        dimension6: params.sectors,
        dimension7: frequency
      });

      await api.subscribeToAlert(email, firstName, frequency, params);
      amplitude.logEvent('API', { site: 'Alerts', function: 'subscribeToAlert', arguments: { email, firstName, frequency, params } });
      setSubmitted(true);
    } catch (error) {
      amplitude.logEvent('Error', { site: 'Alerts', location: 'containers/CreateAlert/index/subscribe', error: error.message });
      setError(error.message)
    }
  }, [api, amplitude]);

  return (
    <div>
      {submitted === false &&
        <div className="card transparent animated fadeIn create-alert">
          <h2>Get the latest ethical jobs sent to your inbox</h2>
        </div>
      }
      <Subscribed
        visible={submitted}
        error={error}
      />
      {submitted === false && (
        <Loading isLoading={fetching}>
          <CreateAlertForm
            subscribe={subscribe}
            taxonomies={taxonomies}
            params={Helpers.getUrlParams()}
            email={email ? decodeURIComponent(email).trim() : ''}
            firstName={firstName ? decodeURIComponent(firstName) : jobSeeker.firstName}
          />
        </Loading>
      )}
      <Navigation />
    </div>
  );
};

export default React.memo(CreateAlert);
