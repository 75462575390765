import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

const Options = ({ terms = {} }) => (
  Object.keys(terms).map(key => (
    <option key={terms[key].slug} value={terms[key].id}>{terms[key].title}</option>
  ))
);

export default ({ terms = {}, label = '', onChange, ...props }) => (
  <FormControl fullWidth={true}>
    <InputLabel>{label}</InputLabel>
    <NativeSelect
      {...props}
      onChange={event => onChange(event)}
    >
      <option value={-1}>Any {label.toLowerCase()}</option>
      <Options terms={terms} />
    </NativeSelect>
  </FormControl>
);
