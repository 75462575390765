import React from "react";

const Logo = () => (
  <a href="https://www.ethicaljobs.com.au">
    <img
      src="https://static.ethicaljobs.com.au/statics/logo-transparent.png"
      alt="EthicalJobs - environmental and socially just jobs"
      className="logo animated fadeInDown"
    />
  </a>
);

export default Logo;
