import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import UnsubscribeButton from 'components/UnsubscribeButton';
import FrequencyDropdown from 'fields/FrequencyDropdown';

const Subscription = ({ uuid, title = 'Unnamed job search alert', frequency, update, unsubscribe }) => {
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(false);

  const onUpdate = useCallback(async (attributes) => {
    setError(false);
    setUpdating(true);
    
    try {
      await update(uuid, attributes);
    } catch (error) {
      setError(true);
    }

    setUpdating(false);
  }, [uuid, update]);

  return (
    <div className={classNames('card subscription', { updating, error })}>
      <div className="content">
        <h4>{title}</h4>
        <FrequencyDropdown
          value={frequency}
          onChange={event => onUpdate({ frequency: event.target.value })}
        />
      </div>
      <Tooltip title="Unsubscribe" placement="top">
        <div className="action" style={{ cursor: 'pointer' }} onClick={() => unsubscribe(uuid)}>
          <UnsubscribeButton />
        </div>
      </Tooltip>
    </div>
  );
};

export default Subscription;
