import React, { Fragment } from "react";
import { withRouter } from 'react-router';
import { Manage, Search, Create, UnsubAll } from "./links";
import Helpers from "lib/helpers";

export const Navigation = ({ unsubscribeAll, showUnsubscribeLink, submitted }) => {
  const credentials = Helpers.getAuthParams();
  
  return (
    <Fragment>
      <ul id="navigation">
        {Helpers.hasCredentials() && (
          <li key="manage">
            <Manage
              label="Manage preferences"
              email={credentials.email}
              secret={credentials.secret}
            />
          </li>
        )}
        {(Helpers.hasCredentials() || submitted) && (
          <li key="create">
            <Create
              label="Create new alert"
              email={credentials.email}
              secret={credentials.secret}
            />
          </li>
        )}
        <li key="search">
          <Search label="Back to job search" />
        </li>
      </ul>
      {showUnsubscribeLink && (
        <ul id="navigation">
          <li key="unsuball">
            <UnsubAll
              label="Unsubscribe All"
              unsubAll={unsubscribeAll}
            />
          </li>
        </ul>
      )}
    </Fragment>
  );
};

export default withRouter(Navigation);
