import React from "react";
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Button from "components/Button";
import FrequencyRadios from "fields/FrequencyRadios";
import TaxonomyDropdown from "fields/TaxonomyDropdown";
import EmailField from "fields/EmailField";
import TextField from "fields/TextField";
import Helpers from "lib/helpers"

export default props => {
  const {
    values,
    touched,
    errors,
    initialValues,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    taxonomies,
  } = props;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={classNames("card create-alert animated fadeIn", { isSubmitting })}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                id="firstName"
                label="Your first name"
                placeholder="Enter your first name"
                type="text"
                value={values.firstName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                disabled={!!(Helpers.hasCredentials() && initialValues.firstName && initialValues.firstName.length > 0)}
              />
            </Grid>
            <Grid item xs={12}>
              <EmailField
                id="email"
                label="Email address"
                placeholder="Enter your email"
                type="text"
                value={values.email || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                disabled={!!(Helpers.hasCredentials() && initialValues.email && initialValues.email.length > 0)}
              />
            </Grid>
            <Grid item xs={12}>
              <FrequencyRadios
                onChange={handleChange}
                value={values.frequency}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classNames("card create-alert animated fadeIn", { isSubmitting })}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TaxonomyDropdown
                name="params.locations"
                terms={taxonomies['locations']}
                label="Location"
                value={values.params.locations}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.locations && errors.locations)}
              />
            </Grid>
            <Grid item xs={12}>
              <TaxonomyDropdown
                name="params.categories"
                terms={taxonomies['categories']}
                label="Classification"
                value={values.params.categories}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.categories && errors.categories)}
              />
            </Grid>
            <Grid item xs={12}>
              <TaxonomyDropdown
                name="params.workTypes"
                terms={taxonomies['workTypes']}
                label="Work Type"
                value={values.params.workTypes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.workTypes && errors.workTypes)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="params.keywords"
                label="Keyword"
                InputLabelProps={{ shrink: true }}
                placeholder="Optional job title, keywords or employer"
                value={values.params.keywords}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.keywords && errors.keywords)}
                helperText={touched.keywords && errors.keywords}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="secondary"
                variant="raised"
                disabled={isSubmitting}
                className="btn btn-block btn-primary"
                style={{ color: "white" }}
              >Subscribe</Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};
