import React from 'react';
import classNames from 'classnames';
import UnsubscribeImage from 'images/Unsubscribe';

const Error = ({ code }) => (
  <div>
    <UnsubscribeImage />
    <div>
      <h3>{"Something went wrong..."}</h3>
      <p>{"Our engineers have been informed and we are working on a solution. Please come back and try again later."}</p>
    </div>
  </div>
);

const Success = ({ jobSeekerUuid }) => (
  <div>
    <UnsubscribeImage />
    <h3>{"You have been unsubscribed."}</h3>
    <p>{"You will no longer receive updates matching this search."}</p>
  </div>
);

export default ({ visible, error, jobSeekerUuid }) => {
  if (visible === false) {
    return null;
  }
  return (
    <div className={classNames('unsubscribed card center animated fadeIn', { error })}>
      {(!error || error === 404) &&
        <Success jobSeekerUuid={jobSeekerUuid} />}
      {(error && error !== 404) &&
        <Error code={error} />}
    </div>
  );
}
