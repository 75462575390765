import { object, string } from "yup";

const SubscribeSchema = object().shape({
    firstName: string().required("Required"),
    email: string().ensure().email("Invalid email address").required("Required"),
    frequency: string().required("Required"),
});

export default {
    SubscribeSchema,
};