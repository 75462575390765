import 'core-js';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import App from 'containers/App';

import './index.css';

ReactGA.initialize('UA-8452399-1', {
  testMode: process.env.NODE_ENV === 'test',
  titleCase: false,
});

ReactDOM.render(<App />, document.getElementById('root'));
