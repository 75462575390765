import React from 'react';
import moment from 'moment';

export default () => (
  <div id="footer">
    <div className="container">
      <div className="copy">
        Copyright &copy; {moment().format('YYYY')} Ethicaljobs.com.au. ABN 89 236 272 208.
      </div>
    </div>
  </div>
);
