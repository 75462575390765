import React from "react";
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    width: '20px',
    color: '#fff',
  },
});

const UnsubscribeButton = ({ classes }) => (
  <IconButton
    className={classes.button}
    aria-label="Unsubscribe"
    color="primary"
  >
    <ClearIcon />
  </IconButton>
);

export default withStyles(styles)(UnsubscribeButton);

