import React, { useState, useEffect, useContext } from 'react';

import { useParams } from 'react-router';
import ReactGA from 'react-ga';

import { APIContext, AmplitudeContext } from 'contexts';
import Unsubscribed from 'components/Unsubscribed';
import Loading from 'components/Loading';
import Navigation from 'components/Navigation';

const Unsubscribe = () => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  const api = useContext(APIContext);
  const amplitude = useContext(AmplitudeContext);

  const { subscriptionUuid } = useParams();

  useEffect(() => amplitude.logEvent('Route', { site: 'Alerts', type: 'Open Page', page: 'Unsubscribe' }), [amplitude]);

  useEffect(() => {
    const fn = async () => {
      setError(false);
      setFetching(true);

      try {
        ReactGA.event({
          category: 'alerts',
          action: 'unsubscribe'
        });
        await api.unsubscribeFromAlert(subscriptionUuid);
        amplitude.logEvent('API', { site: 'Alerts', function: 'unsubscribeFromAlert', arguments: { subscriptionUuid } });
      } catch (error) {
        amplitude.logEvent('Error', { site: 'Alerts', location: 'containers/Unsubscribe:unsubscribeFromAlert', error: error.message });
        setError(true);
      }

      setFetching(false);
    };

    if (subscriptionUuid) fn();
  }, [subscriptionUuid, api, amplitude]);

  return (
    <Loading isLoading={fetching}>
      <Unsubscribed error={error} visible />
      <Navigation />
    </Loading>
  );
}

export default React.memo(Unsubscribe);