import get from 'lodash/get';
import queryString from 'query-string';

/**
 * Determines if Jobseeker credentials are present
 * @return bool
 */
function hasCredentials() {
  const { email, secret } = getAuthParams();
  return (email && secret) ? true : false;
}

/**
 * Get url params for the current location
 * @return {object}
 */
function getUrlParams() {
  const urlParams = get(window, 'location.search', '');
  return queryString.parse(urlParams);
}

/**
 * Retrieves auth params
 * @return {object}
 */
function getAuthParams() {
  const params = getUrlParams();
  return {
    email: get(params, 'email', '').trim(),
    secret: get(params, 'secret', ''),
  };
}

export default {
  getUrlParams,
  getAuthParams,
  hasCredentials
};
