import React from "react";
import posed, { PoseGroup } from 'react-pose';
import Subscription from 'components/Subscription';
import Empty from './Empty';

const STAGGER_DELAY = 100;

const Item = posed.li({
  before: {
    x: "-100%",
    opacity: 0,
  },
  enter: {
    delay: ({ i, total }) => (total * STAGGER_DELAY) + (i * STAGGER_DELAY),
    x: "0%",
    opacity: 1,
  },
});

const SubscriptionsList = ({ subscriptions = [], unsubscribe, update }) => {
  if (subscriptions.length < 1) {
    return <Empty />;
  }

  return (
    <ul className="list subscriptions">
      <PoseGroup animateOnMount preEnterPose="before">
        {subscriptions.map((subscription, num) =>
          <Item key={subscription.uuid} total={3} i={num}>
            <Subscription
              {...subscription}
              unsubscribe={unsubscribe}
              update={update}
            />
          </Item>)}
      </PoseGroup>
    </ul>
  );
};

export default SubscriptionsList;
