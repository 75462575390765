import React from "react";
import classNames from "classnames";

export default ({ className, children, disabled, ...rest }) => (
  <button
    {...rest}
    disabled={disabled}
    className={classNames("btn", className, { "btn-disabled": disabled })}
  >
    {children}
  </button>
);
